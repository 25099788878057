import axios from "@/axios";
const url = "/api";

class MgtCategoryService {
	async getMgtCategoryByModule({ module_id, start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/mgt-category/${module_id}`, {
				params: { start_date, end_date },
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	async createMgtCategoryByModule({ name, description, parent_id, type }) {
		try {
			const { data } = await axios.post(`${url}/mgt-category/store`, {
				name,
				description,
				parent_id,
				type,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updateMgtCategoryByModule({ name, description, parent_id, type, id }) {
		try {
			const {data} = await axios.put(`${url}/mgt-category/update/${id}`, {
				name,
				description,
				parent_id,
				type,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async deleteMgtCategoryByModule({ id }) {
		try {
			const { data } = await axios.delete(`${url}/mgt-category/destroy/${id}`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getDetailsCategories({ id, start_date, end_date }) {
		try {
			const { data } = await axios.get(`${url}/mgt-category/show-details/${id}`, {
				params: { start_date, end_date },
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	//mgt-category/1
}
export default new MgtCategoryService();
