<template>
	<div>
		<!-- {{text_filter_null}} -->
		<v-select
							label="label"
							class="w-100"
							v-model="category_id"
							:options="arrayCategory
										.map(it => ({...it, label: (text_filter_null && !it.id) ? 'Todos' : it.label}))
										.filter(it => (it.id && defaultNull) || !defaultNull)
										.filter(it => it.id != filterBy)
									"
							:clearable="false"
							:reduce="(option) => option.id"
							transition=""
							:placeholder="`Seleccionar categoria`"
                            @input="(e) => setCategory(e)"
						>
							<template slot="no-options"> Sin categorias encontradas </template>

							<template slot="option" slot-scope="option">
								<div class="d-center d-flex align-items-center">
									
									<p class="mb-0" :style="{ marginLeft: `${(option.level - 1) * 10}px` }">
										<div class="circle"></div> 
										{{ option.label }}
									</p>
								</div>
							</template>
							<template slot="selected-option" slot-scope="option">
								<div class="selected d-center d-flex align-items-center">
									
									<p class="mb-0" >
										<div class="circle"></div>
										{{ option.label }}
									</p>
								</div>
							</template>
						</v-select>
	</div>
   
</template>

<script>
import {mapState, mapMutations} from 'vuex'
export default {
    name: 'SelectCategoryItems',
    computed: {
		...mapState("orders", ["arrayCategory"]),
	},
    props: {
		value: {
			type: Number,
		},
		text_filter_null: {
			type: Boolean,
			default: false
		},
		defaultNull: {
			type: Boolean,
			default: false
		},
		filterBy: {
			type: Number,
			default: null
		}
	},
    data() {
        return {
            category_id: this.value
        }
    },
    methods: {
        ...mapMutations("orders", ["SET_SEARCH_ITEMS"]),
        setCategory(e) {
			this.$emit('input', e);
			this.category_id = e;
            this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: e,
			});
		},
    },
    watch: {
        value(val) {
			this.category_id = val;
			
		},
    }
}

</script>

<style lang="scss">
.circle{
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background: #3a3a3a;
	margin-right: .5rem;
}
</style>