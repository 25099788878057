<template>
	<div
		class="sp-drop h-full w-full"
		@dragover.prevent="dragOver"
		@dragleave.prevent="dragLeave"
		@drop.prevent="drop($event)"
		:class="{ empty: !image }"
	>
		<Transition name="spi-empty" mode="out-in">
			<img v-if="image" :src="image" alt="" @error="foundErrorLoadImage" />
			<div class="spi-empty abs-center" v-else>
				<svg width="65" height="57" fill="none" viewBox="0 0 65 57">
					<path
						fill="currentColor"
						d="M32.5 42C26.701 42 22 37.299 22 31.5S26.701 21 32.5 21 43 25.701 43 31.5 38.299 42 32.5 42Z"
					/>
					<path
						fill="currentColor"
						d="m42.25 0 5.947 6.333H58.5c3.575 0 6.5 2.85 6.5 6.334v38C65 54.15 62.075 57 58.5 57h-52C2.925 57 0 54.15 0 50.667v-38c0-3.484 2.925-6.334 6.5-6.334h10.302L22.75 0h19.5ZM32.5 47.5c8.97 0 16.25-7.093 16.25-15.833S41.47 15.833 32.5 15.833c-8.97 0-16.25 7.094-16.25 15.834 0 8.74 7.28 15.833 16.25 15.833Z"
					/>
				</svg>
			</div>
		</Transition>
		<input
			:disabled="disabled"
			type="file"
			name=""
			:id="`spi-file-${id}`"
			accept=".jpeg,.jpg,.png,.heif,.heic"
			hidden
			@change="fileChange($event.target)"
		/>
		<div class="spi-actions">
			<button :disabled="disabled" type="button" class="spi-button bg-danger" @click="deleteFile" v-if="image">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 14 13">
					<path stroke="#fff" stroke-linecap="round" stroke-width="2" d="m1 1 12 11m0-11L1 12" />
				</svg>
			</button>
			<label :disabled="disabled" class="spi-button" :for="`spi-file-${id}`">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" fill="none" viewBox="0 0 20 15">
					<path fill="#fff" d="M9.063 10.313h1.874V4.686h2.813L10 0 6.25 4.688h2.813v5.625Z" />
					<path
						fill="#fff"
						d="M17.5 13.125h-15V6.562H.625v6.563C.625 14.159 1.465 15 2.5 15h15c1.034 0 1.875-.84 1.875-1.875V6.562H17.5v6.563Z"
					/>
				</svg>
			</label>
		</div>
	</div>
</template>

<script>
import heic2any from "heic2any";
export default {
	name: "SelectableImage",
	props: {
		value: {
			type: [File, String],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.id = this._uid;
	},
	methods: {
		fileChange(e) {
			this.fileReader(e.files[0]);
		},
		drop(e) {
			e.stopPropagation();
			let files = e.dataTransfer.files;
			this.fileReader(files[0]);
		},
		async fileReader(file) {
			let nFile = file;
			if (["image/heif", "image/heic"].includes(file.type)) {
				await heic2any({ blob: file, toType: "image/jpg", quality: 1 }).then((newImage) => {
					nFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					});
				});
			}
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const imageURI = reader.result;
				this.image = imageURI;
			});
			reader.readAsDataURL(nFile);
			this.$emit("input", nFile);
			this.$emit("updateStatusImage", true);
		},
		deleteFile() {
			this.image = null;
			this.$emit("input", null);
			this.$emit("updateStatusImage", true);
		},
		dragOver(e) {},
		dragLeave(e) {},
		foundErrorLoadImage(event) {
			event.target.src = require("@/assets/images/rooster.jpg");
		},
	},
	data() {
		return {
			image: this.value,
			id: null,
			file: null,
		};
	},
	watch: {
		value(val) {
			this.image = val;
		},
	},
};
</script>

<style lang="scss">
.sp-drop {
	min-height: 100%;
	position: relative;
	aspect-ratio: 3/2.6;
	border-radius: 0.32rem;
	overflow: hidden;
	transition: border 300ms ease-in-out;
	border: 2px dashed transparent;
	img {
		max-width: 100%;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	&.empty {
		border-color: #aeacac;
	}
	.dark-layout &.empty {
		border-color: #404656;
	}
}

.spi-actions {
	position: absolute;
	top: 0.6rem;
	right: 0.5rem;
	display: inline-flex;
	flex-direction: column;
	row-gap: 0.7rem;
	z-index: 2;
}

.spi-button {
	display: inline-flex;
	background-color: var(--primary);
	outline: none;
	border: none;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	padding: 0.6rem 0.5rem;
	cursor: pointer;

	&[disabled] {
		opacity: 50%;
		cursor: not-allowed;
	}
}
.spi-empty {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	color: #aeacac;
	background-color: #e4e4e4;
	svg {
		width: 26%;
		height: 26%;
	}
	.dark-layout & {
		background-color: #161d31;
	}
}
.spi-empty-enter-active,
.spi-empty-leave-active {
	transition: opacity 300ms ease-in-out;
}

.spi-empty-enter-from,
.spi-empty-leave-to {
	opacity: 0;
}
</style>
