<template>
	<div v-if="item">
		<validation-observer ref="form">
			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Nombre">
					<b-form-input
						placeholder="Agregar nombre"
						id="input-1"
						v-model="item.name"
						trim
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-input>
					<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
				</b-form-group>
			</ValidationProvider>

			<ValidationProvider rules="" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Description">
					<b-form-textarea
						id="textarea"
						v-model="item.description"
						placeholder="Agregar description"
						rows="3"
						max-rows="6"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-textarea> </b-form-group
			></ValidationProvider>

			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<div class="d-flex align-items-center w-100">
					<b-form-group
						label="Categoria"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
						class="mb-0 w-100"
					>
						<SelectCategoryItems v-model="item.category_id" />
					</b-form-group>
				</div>
			</ValidationProvider>

			<b-row>
				<b-col cols="12">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group
							:invalid-feedback="errors[0]"
							:state="errors[0] ? false : null"
							class="mt-1"
							id="fieldset-1"
							label="Precio"
						>
							<b-row>
								<b-col>
									<v-select
										v-model="item.currency"
										:options="arrayCurrency"
										transition=""
										label="description"
										placeholder="Moneda"
										:reduce="(option) => option.id"
										class="mr-50"
										:style="{ width: '100%' }"
									/>
								</b-col>
								<b-col>
									<money
										:state="errors[0] ? false : null"
										v-bind="money"
										v-model="item.price"
										class="form-control"
										placeholder="Precio del ejemplar"
										:class="errors[0] ? 'border-danger' : ''"
									></money>
								</b-col>
							</b-row>
						</b-form-group>
					</ValidationProvider>
				</b-col>

				<!-- <b-col cols="4">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group
							:invalid-feedback="errors[0]"
							:state="errors[0] ? false : null"
							class="mt-1"
							id="fieldset-1"
							label="Unidad de medida"
						>
							<v-select
								v-model="item.units"
								:options="arrayUnits"
								transition=""
								label="name"
								placeholder="U/M"
								:reduce="(option) => option.id"
								class="mr-50"
								:style="{ width: '100%' }"
							/>
						</b-form-group>
					</ValidationProvider>
				</b-col> -->
			</b-row>

			<SelectableImage v-model="item.image" @updateStatusImage="updateStatusImage"/>
		</validation-observer>
		<div class="mt-2 d-flex justify-content-end">
			<button class="btn btn-primary" @click="saveItem">Guardar</button>
		</div>
	</div>
</template>

<script>
import { Money } from "v-money";
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import utilitiesService from "@/services/utilities.service";
import itemsService from "@/services/items.service";
import { getBase64 } from "@/helpers/functions";
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue";
import mgtCategoryService from "@/services/mgt-category.service";
import SelectCategoryItems from "@/views/brain/others/views/categories/components/SelectCategoryItems.vue";

export default {
	name: "EditItemBrain",
	components: { Money, DragAndDropImages, SelectableImage, SelectCategoryItems },
	props: {
		itemEdit: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			categories: [],
			item: {},
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false,
			},
			arrayCurrency: [],
			arrayUnits: [],
			status_image: false
		};
	},
	async mounted() {
		await this.getUnits();
		await this.getCurrency();
		await this.initCategories();
		this.item = {
			name: this.itemEdit.name,
			description: this.itemEdit.description,
			category_id: this.itemEdit.category.id,
			price: this.itemEdit.price,
			currency: this.itemEdit.currency.id,
			units: this.itemEdit.um.id,
			image: this.itemEdit.images != null ? this.itemEdit.images[0].url : null,
		};
		//this.isPreloading(false);
	},
	methods: {
		async saveItem() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			if(this.item.price == 0) {
				return this.showToast("warning", "top-right", "Producto", "WarningIcon", "El precio es obligatorio");
			}
			this.isPreloading();

			const dataset = new FormData();
			dataset.append("name", this.item.name);
			this.item.description && dataset.append("description", this.item.description);
			dataset.append("type", this.item.type);
			dataset.append("price", this.item.price);
			dataset.append("currency_id", this.item.currency);
			dataset.append("um_id", this.item.units);
			dataset.append("mgt_category_id", this.item.category_id);
			dataset.append("if_save_image", this.status_image)

			if (typeof this.item.image == "string") {
				delete this.item.image;
			} else {
				this.item.image && dataset.append("image", this.item.image);
			}
			const { message } = await itemsService.updateItem({ id: this.itemEdit.id, body: dataset });
			this.showToast("success", "top-right", "Item", "SuccessIcon", message);
			this.isPreloading(false);
			this.$emit("refresh");
		},
		async getUnits() {
			this.isPreloading();
			const resp = await utilitiesService.getUnits();
			this.isPreloading(false);
			this.arrayUnits = resp;
		},
		async getCurrency() {
			this.isPreloading();
			const resp = await utilitiesService.getCurrency();
			this.isPreloading(false);
			this.arrayCurrency = resp;
		},
		async initCategories() {
			const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({ module_id: 1 });
			this.categories = data;
		},
		updateStatusImage(status) {
			this.status_image = status
		}
	},
};
</script>

<style lang="scss">
.image-skeletor {
	height: 200px;
	width: 100%;
	background: #f5f5f5;
}
</style>
