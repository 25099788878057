<template>
	<div>
		<div class="flex flex-row justify-content-start">
			<button class="btn btn-primary d-flex align-items-center" @click="modalViewAddCategory.show = true">
				<FeatherIcon class="mr-25" icon="PlusIcon" size="18" />
				Nuevo producto
			</button>
		</div>

		<b-row class="">
			<b-col cols="12" md="12" lg="4" class="d-flex flex-column align-items-start mt-3 justify-content-between">
				<span class="text-muted">
					Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
				</span>
			</b-col>
			<!-- Pagination -->
			<b-col cols="12" md="12" lg="8" class="flex-nowrap">
				<div class="d-flex justify-content-end flex-column align-items-end mt-2">
					<div class="xt-search-input__paginate">
						<b-pagination
							:value="jsonSearchItems.page"
							:total-rows="total_data"
							:per-page="jsonSearchItems.perpage"
							first-number
							last-number
							class=""
							prev-class="prev-item"
							next-class="next-item"
							@input="(e) => setPage(e)"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</div>
					<div class="xt-search-input__search">
						<div class="d-flex align-items-center">
							<b-form-input
								type="search"
								class="mr-1"
								:value="jsonSearchItems.search"
								placeholder="Buscar"
								style="width: 200px"
								@keyup.enter="init"
								@input="(e) => writeSearch(e)"
							/>
							<div class="d-flex align-items-center">
								<button @click="init" class="btn btn-primary mr-50">Buscar</button>
								<FeatherIcon
									@click="viewSidebarFilters"
									icon="FilterIcon"
									size="20"
									class="w-25 text-primary cursor-pointer"
								/>
							</div>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>

		<!-- {{items}} -->
		<div class="card mt-2 mx-auto">
			<b-table-simple class="table mb-0">
				<b-thead>
					<b-tr class="text-center">
						<b-th> # </b-th>
						<b-th> Imagen </b-th>
						<b-th> Nombre </b-th>
						<b-th> Categoria </b-th>
						<b-th> Descripcion </b-th>
						<b-th> Precio </b-th>
						<!-- <b-th> Unidad </b-th> -->
						<b-th> Acciones </b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in items" :key="index">
						<template v-if="items.length > 0">
							<b-td class="">
								<p class="mb-0">{{ index + 1 }}</p>
							</b-td>

							<b-td>
								<img
									class="img-container shadow"
									onerror="this.src='/rooster/not-item-img.png'"
									:src="item.images ? item.images[0].thumb : '/rooster/not-item-img.png'"
									alt=""
								/>
							</b-td>

							<b-td class="">
								<p :style="{ marginLeft: `${(item.level - 1) * 30}px` }" class="mb-0">
									{{ item.name }}
								</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">
									{{ item.category.text }}
								</p>
							</b-td>

							<b-td class="">
								<p
									class="mb-0"
									v-b-tooltip.hover
									:title="item.description && item.description.length > 60 ? item.description : null"
								>
									{{
										item.description && item.description.length > 60
											? `${item.description.slice(0, 60)}...`
											: item.description
									}}
								</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">
									{{ `${item.currency.text} ${item.price}` }}
									<FeatherIcon
										@click="viewModalTrackingPrices(item.id)"
										icon="ListIcon"
										size="18"
										v-b-tooltip.hover
										title="Ver seguimiento"
										class="cursor-pointer"
									/>
								</p>
							</b-td>

							<!-- <b-td class="">
								<p class="mb-0">{{ `${item.um.text}` }}</p>
							</b-td> -->

							<b-td class="">
								<FeatherIcon
									@click="
										modalViewEditCategory.show = true;
										modalViewEditCategory.item = item;
									"
									icon="EditIcon"
									size="20"
									class="text-primary cursor-pointer mr-25"
								/>
								<FeatherIcon
									@click="deleteItem(item.id)"
									icon="Trash2Icon"
									size="20"
									class="text-danger cursor-pointer"
								/>
							</b-td>
						</template>
					</b-tr>
					<b-tr v-if="items.length == 0">
						<b-td colspan="7" class="text-center">
							<p class="mb-0 font-weight-bold">SIN PRODUCTOS ENCONTRADOS</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

		<b-modal
			@hide="setCategory(null)"
			v-model="modalViewAddCategory.show"
			:title="modalViewAddCategory.title"
			hide-footer
			v-if="modalViewAddCategory.show"
		>
			<AddItemBrain @refresh="refresh" />
		</b-modal>

		<b-modal
			@hide="setCategory(null)"
			v-model="modalViewEditCategory.show"
			:title="modalViewEditCategory.title"
			hide-footer
			v-if="modalViewEditCategory.show"
		>
			<EditItemBrain @refresh="refresh" :itemEdit="modalViewEditCategory.item" />
		</b-modal>

		<b-modal v-model="modalViewTrackingPrices.show" :title="modalViewTrackingPrices.title" hide-footer>
			<div class="card mt-0 mb-0 mx-auto">
				<b-table-simple class="table mb-0">
					<b-thead>
						<b-tr class="text-center">
							<b-th> # </b-th>
							<b-th> Precio </b-th>
							<b-th> Usuario </b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in modalViewTrackingPrices.data" :key="index">
							<template v-if="modalViewTrackingPrices.data.length > 0">
								<b-td class="">
									<p class="mb-0">{{ index + 1 }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.price }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.created.user }}</p>
									<span>{{ item.created.date | myGlobalDayShort }}</span>
								</b-td>
							</template>
						</b-tr>
						<b-tr v-if="modalViewTrackingPrices.data.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">_ _</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>

		<b-sidebar
			@hidden="setCategory(null)"
			v-model="sidebarFilterItems.show"
			body-class="bg-white p-2"
			:title="sidebarFilterItems.title"
		>
			<div class="d-flex flex-column align-items-center w-100">
				<b-form-group label="Categoria" class="mb-0 w-100">
					<SelectCategoryItems text_filter_null v-model="jsonSearchItems.category_id" />
				</b-form-group>
				<div class="w-100 mt-2">
					<button class="btn btn-primary w-100" @click="init">Filtrar</button>
					<button class="btn btn-info w-100 mt-1" @click="resetFilters">Limpiar filtros</button>
				</div>
			</div>
		</b-sidebar>
	</div>
</template>

<script>
import AddItemBrain from "./AddItemBrain.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import itemsService from "@/services/items.service";
import EditItemBrain from "./EditItemBrain.vue";
import mgtCategoryService from "@/services/mgt-category.service";
// import SelectCategoryItems from "../../mgt-category/components/SelectCategoryItems.vue";
import SelectCategoryItems from "@/views/brain/others/views/categories/components/SelectCategoryItems.vue";

export default {
	name: "ItemListBrain",
	components: { AddItemBrain, EditItemBrain, SelectCategoryItems },
	data() {
		return {
			modalViewAddCategory: {
				show: false,
				title: "Nuevo producto",
			},
			sidebarFilterItems: {
				show: false,
			},
			page: 1,
			modalViewEditCategory: {
				show: false,
				title: "Editar producto",
				item: null,
			},
			modalViewTrackingPrices: {
				show: false,
				title: "Seguimiento de precios",
				data: [],
			},
			categories: [
				{
					id: null,
					level: 1,
					name: "Todos",
					type: "expense",
					updated_at: "2022-12-01 15:52:32",
					updated_by: 1,
				},
			],
		};
	},
	async mounted() {
		this.SET_SEARCH_ITEMS({
			key: "category_id",
			value: null,
		});
		await this.init();
	},
	computed: {
		...mapState("orders", [
			"jsonSearchItems",
			"items",
			"start_page",
			"to_page",
			"total_data",
			"current_page",
			"arrayCategory",
		]),
	},
	methods: {
		...mapMutations("orders", ["SET_SEARCH_ITEMS"]),
		...mapActions("orders", ["getNotifications"]),
		async init() {
			this.isPreloading();

			await this.getNotifications(this.jsonSearchItems);

			this.isPreloading(false);
		},
		async refresh() {
			await this.init();
			this.modalViewAddCategory.show = false;
			this.modalViewEditCategory.item = null;
			this.modalViewEditCategory.show = false;
		},
		writeSearch(e) {
			this.SET_SEARCH_ITEMS({
				key: "search",
				value: e,
			});
			this.jsonSearchItems.search.length == 0 && init();
		},
		async setPage(e) {
			this.SET_SEARCH_ITEMS({
				key: "page",
				value: e,
			});
			await this.init();
		},
		async deleteItem(id) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const resp = await itemsService.deleteItem({ id });
			await this.init();
		},
		async viewModalTrackingPrices(id) {
			this.isPreloading();

			const { data } = await itemsService.getTrackingPrices({ id });

			this.isPreloading(false);
			if (data.length == 0) {
				this.showToast("info", "top-right", "Items", "InfoIcon", "El item no tiene cambios de precio.");
			} else {
				this.modalViewTrackingPrices.data = data;
				this.modalViewTrackingPrices.show = true;
			}
		},
		async viewSidebarFilters() {
			this.sidebarFilterItems.show = !this.sidebarFilterItems.show;
			// if (this.sidebarFilterItems.show && this.categories.length == 1) {
			// 	await this.initCategories();
			// }
		},
		async initCategories() {
			const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({ module_id: 1 });
			this.categories = [...this.categories, ...data];
		},
		async resetFilters() {
			this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: null,
			});
			this.SET_SEARCH_ITEMS({
				key: "page",
				value: 1,
			});
			this.SET_SEARCH_ITEMS({
				key: "search",
				value: null,
			});
			await this.init();
		},
		setCategory(e) {
			this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: e,
			});
		},
	},
};
</script>

<style lang="scss">
.img-container {
	width: 60px !important;
	height: 60px !important;
	object-fit: cover;
	background: #f5f5f5;
	border-radius: 4px;
}
</style>
