var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Nombre"}},[_c('b-form-input',{attrs:{"placeholder":"Agregar nombre","id":"input-1","trim":"","invalid-feedback":errors[0],"state":errors[0] ? false : null},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Description"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Agregar description","rows":"3","max-rows":"6","invalid-feedback":errors[0],"state":errors[0] ? false : null},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":"Categoria","invalid-feedback":errors[0],"state":errors[0] ? false : null}},[_c('SelectCategoryItems',{attrs:{"defaultNull":""},model:{value:(_vm.item.category_id),callback:function ($$v) {_vm.$set(_vm.item, "category_id", $$v)},expression:"item.category_id"}})],1)],1)]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"invalid-feedback":errors[0],"state":errors[0] ? false : null,"id":"fieldset-1","label":"Precio"}},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"mr-50",style:({ width: '100%' }),attrs:{"options":_vm.arrayCurrency,"transition":"","label":"description","placeholder":"Moneda","reduce":function (option) { return option.id; }},model:{value:(_vm.item.currency),callback:function ($$v) {_vm.$set(_vm.item, "currency", $$v)},expression:"item.currency"}})],1),_c('b-col',[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"state":errors[0] ? false : null,"placeholder":"Precio del ejemplar"},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}},'money',_vm.money,false))],1)],1)],1)]}}])})],1)],1),_c('SelectableImage',{model:{value:(_vm.item.image),callback:function ($$v) {_vm.$set(_vm.item, "image", $$v)},expression:"item.image"}})],1),_c('div',{staticClass:"mt-2 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.saveItem}},[_vm._v("Guardar")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }